import { Accordion, Col, Form, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import CartProductItem from './product-item'
import ProductEditModal from './ProductEditModal'
import Icon from '../../app-components/icon'
import Checkbox from '../../app-components/checkbox'
import ExpressShippingModal from './ExpressShippingModal'
import {
  getCart,
  getExtras,
  getProductType,
  getCartProduct,
  getProductById,
  getCartProducts,
  getShipping,
} from '../../../store/offer/selectors'
import { getStudioById } from '../../../store/studios/selectors'
import {
  doAddCoupon,
  doAddToCart,
  doGetCategoryBySlug,
  doGetProductById,
  doGetProductsByCategory,
  doUpdateExtras,
} from '../../../store/offer/actions'
import Link from '../../app-components/link'

import './styles.scss'

const CartStep = ({
  cart,
  location,
  cartProduct,
  getStudio,
  fetchProductById,
  fetchProductsByCategory,
  fetchCategoryBySlug,
  getProduct,
  getType,
  extras,
  toggleExtras,
  addToCart,
  deleteProduct,
  acceptTermsAndConditions,
  toggleTermsAndConditions,
  submitted,
  cartProducts,
  addCoupon,
  extraShipping,
}) => {
  const [showEditModal, toggleEditModal] = useState(false)
  const [productToEdit, setProductToEdit] = useState(null)
  const [showPromo, setShowPromo] = useState(false)
  const [coupon, setCoupon] = useState(cart?.coupon || null)
  const [showExpressModal, toggleExpressModal] = useState(
    location?.state?.showModal || false
  )

  useEffect(() => {
    async function init() {
      const category = await fetchCategoryBySlug(`b2c_extras`)
      if (category) {
        await fetchProductsByCategory(category?.id)
      }
      cart.products.forEach(p => fetchProductById(p.product_id))
    }

    init()
  }, [])

  useEffect(() => {
    if (location?.state?.showModal && extraShipping !== null) {
      toggleExpressModal(true)
    } else if (location?.state?.editProduct) {
      setProductToEdit(getProduct(location.state.editProduct))
      toggleEditModal(true)
    }
  }, [location.state, extraShipping])

  useEffect(() => {
    if (cart.coupon === '') {
      setCoupon(null)
    }
  }, [cart])

  useEffect(() => {
    if (cart.coupon === '') {
      setCoupon(null)
    }
  }, [cart])

  const editProduct = product => {
    setProductToEdit(product)
    toggleEditModal(true)
  }

  const confirmEditProduct = async newProduct => {
    // remove actual product from cart
    await deleteProduct(cartProduct)
    await addToCart(newProduct)

    setProductToEdit(null)
    toggleEditModal(false)
  }

  const checkPromo = async e => {
    const { value } = e.target
    if (value === '') {
      setCoupon(null)
    } else {
      const result = await addCoupon(value)
      if (result) {
        // success
        setCoupon(true)
      } else {
        // failure
        setCoupon(false)
      }
    }
  }
  if (cartProducts.length > 0)
    return (
      <Row>
        <Col className="checkout-cart left-content">
          <div className="cart-product-list">
            {cartProducts?.map((product, index) => (
              <CartProductItem
                key={product.key}
                index={index}
                cartProduct={product}
                product={getProduct(product.product_id)}
                selectedProduct={cartProduct}
                getStudio={getStudio}
                getProduct={getProduct}
                editProduct={editProduct}
                getType={getType}
                extras={extras}
                toggleExtra={toggleExtras}
                fetchProductById={fetchProductById}
                handleDelete={() => deleteProduct(cartProduct)}
              />
            ))}
          </div>
          <Row>
            <Col className="border-bottom accordion-cart-wrapper">
              <Accordion className="accordion-section" activeKey={showPromo}>
                <Accordion.Toggle
                  className="accordion-section-title"
                  as="div"
                  eventKey="promo"
                  onClick={() => setShowPromo(showPromo ? '' : 'promo')}
                >
                  <p className="my-3 my-md-3">
                    Avez-vous un code promotionnel ?{' '}
                    <Icon
                      name="icon-arrow-down"
                      iconClass={`icon-arrow-down1 float-right ${
                        showPromo ? '' : 'up'
                      }`}
                    />
                  </p>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="promo">
                  <Row>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        placeholder="Code promotionnel"
                        name="promocode"
                        onChange={checkPromo}
                        defaultValue={cart?.coupon}
                        isInvalid={
                          coupon !== null &&
                          (coupon === false || cart?.coupon_status !== '1')
                        }
                      />
                    </Col>
                    <Col className="promo-icons pl-0">
                      {coupon && <Icon name="icon-check" />}
                    </Col>
                  </Row>
                </Accordion.Collapse>
              </Accordion>
            </Col>
          </Row>
          <Row className="mt-3 mt-md-3 optin-cgu">
            <Col>
              <Checkbox
                id="optin-cgu"
                square
                onClick={e => {
                  e.stopPropagation()
                  toggleTermsAndConditions(!acceptTermsAndConditions)
                }}
                label={
                  <>
                    Vous avez pris connaissance et accepté les{' '}
                    <Link to="/conditions-generales-utilisation">
                      conditions générales d&apos;utilisation
                    </Link>
                  </>
                }
                labelClass="ml-2 d-inline mx-md-2"
                className={`${
                  !acceptTermsAndConditions && submitted
                    ? 'error-cgu-checkbox'
                    : ''
                } d-md-flex`}
                isChecked={!!acceptTermsAndConditions}
              />
            </Col>
          </Row>
        </Col>
        <ProductEditModal
          handleEdit={confirmEditProduct}
          setShow={toggleEditModal}
          show={showEditModal}
          product={productToEdit}
          selectedProduct={cartProduct}
        />
        <ExpressShippingModal
          extraProduct={extraShipping}
          handleToggle={id => {
            toggleExtras(0, id)
          }}
          show={showExpressModal}
          setShow={toggleExpressModal}
          isChecked={
            cart?.products.length > 0 &&
            extras?.[0] &&
            extras[0].includes(extraShipping?.id)
          }
        />
      </Row>
    )
  return (
    <Col className="checkout-cart left-content">
      <p>Votre panier est vide</p>
    </Col>
  )
}

CartStep.propTypes = {
  cart: propTypes.objectOf(propTypes.any).isRequired,
  location: propTypes.objectOf(propTypes.any).isRequired,
  cartProduct: propTypes.objectOf(propTypes.any).isRequired,
  getStudio: propTypes.func.isRequired,
  fetchProductById: propTypes.func.isRequired,
  fetchProductsByCategory: propTypes.func.isRequired,
  fetchCategoryBySlug: propTypes.func.isRequired,
  getProduct: propTypes.func.isRequired,
  extraShipping: propTypes.objectOf(propTypes.any),
  getType: propTypes.func.isRequired,
  extras: propTypes.arrayOf(propTypes.object).isRequired,
  toggleExtras: propTypes.func.isRequired,
  addToCart: propTypes.func.isRequired,
  deleteProduct: propTypes.func.isRequired,
  acceptTermsAndConditions: propTypes.bool.isRequired,
  toggleTermsAndConditions: propTypes.func.isRequired,
  submitted: propTypes.bool.isRequired,
  cartProducts: propTypes.arrayOf(propTypes.object).isRequired,
  addCoupon: propTypes.func.isRequired,
}

CartStep.defaultProps = { extraShipping: null }

const mapStateToProps = state => ({
  cart: getCart(state),
  cartProduct: getCartProduct(state),
  getStudio: id => getStudioById(state, id),
  getProduct: id => getProductById(state, id),
  getType: id => getProductType(state, id),
  extras: getExtras(state),
  cartProducts: getCartProducts(state),
  extraShipping: getShipping(state),
})

const mapDispatchToProps = dispatch => ({
  fetchProductById: id => dispatch(doGetProductById(id)),
  fetchProductsByCategory: category =>
    dispatch(doGetProductsByCategory(category)),
  fetchCategoryBySlug: slug => dispatch(doGetCategoryBySlug(slug)),
  toggleExtras: (productId, extraId) =>
    dispatch(doUpdateExtras(productId, extraId)),
  addToCart: product => dispatch(doAddToCart(product)),
  deleteProduct: product => dispatch(doAddToCart(product, -1)),
  addCoupon: coupon => dispatch(doAddCoupon(coupon)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CartStep)
