import { Button, Col, Row } from 'react-bootstrap'
import React from 'react'
import { navigate } from 'gatsby'
import upperFirst from 'lodash/upperFirst'
import propTypes from 'prop-types'
import moment from 'moment'
import { decode } from 'he'

import Link from '../../../app-components/link'
import Checkbox from '../../../app-components/checkbox'
import Icon from '../../../app-components/icon'
import { STEPS_LABEL } from '../../../../constants/steps'

import './styles.scss'

const CartProductItem = ({
  index,
  cartProduct,
  product,
  editProduct,
  isSummary,
  selectedProduct,
  getStudio,
  getProduct,
  extras,
  toggleExtra,
  handleDelete,
}) => {
  const studio = getStudio(selectedProduct?.studio)
  const date = moment(selectedProduct?.date)
  const category =
    product?.category?.[1]?.[0]?.meta_title.split(' ')[1].toLowerCase() ??
    'portrait'

  return (
    <>
      <Row className="pb-3 pb-md-3 border-bottom cart-product-header">
        <Col className="">
          <h3 className="mb-2">
            Votre séance
            {isSummary && (
              <Button
                onClick={() =>
                  navigate(`/particuliers/${category}/seance`, {
                    state: { edit: true },
                  })
                }
                variant="flat"
                className="float-right  pr-md-0"
              >
                <Icon name="icon-edit-pen" />
              </Button>
            )}
          </h3>
          <Row>
            <Col xs="12" md>
              <p className="mb-1 mb-md-0">
                <b>Lieu :</b> {studio?.name} - {studio?.postalcode}{' '}
                {studio?.city}
                <br />
                <b>Date :</b> {date.format('dddd DD MMMM')}
                <br />
                <b>Créneau horaire : </b>{' '}
                {date.format('HH:mm').replace(':', 'H')}
              </p>
            </Col>
            {!isSummary && (
              <Col
                md="auto"
                className="d-flex justify-content-end align-items-end update-links"
              >
                <Link
                  className="btn btn-link btn-sm"
                  to={`/particuliers/${category}/seance`}
                  state={{ edit: true }}
                >
                  Modifier
                </Link>
                <Button variant="link" size="sm" onClick={handleDelete}>
                  Supprimer
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row className="cart-product-item my-3 my-md-3 pb-md-2">
        <Col xs="4" md="3" className="pr-0 cart-product-item-left">
          <img
            src={product?.original_image}
            alt={cartProduct.name}
            className="img-fluid"
          />
        </Col>
        <Col xs="8" md="9" className="cart-product-item-right">
          <h4 className="cart-product-item-title mb-1 mb-md-1">
            {cartProduct.name}
            {!isSummary && (
              <strong className="float-right">{cartProduct.price}</strong>
            )}
            {isSummary && (
              <Button
                onClick={() => editProduct(cartProduct)}
                variant="flat"
                className="float-right pr-md-0"
              >
                <Icon name="icon-edit-pen" />
              </Button>
            )}
          </h4>
          <p className="cart-product-item-details mb-1 mb-md-2">
            {cartProduct.option.map(o =>
              Object.keys(STEPS_LABEL).includes(o.name) ? (
                <>
                  {`${upperFirst(STEPS_LABEL[o.name])} ${upperFirst(
                    decode(o.value)
                  )}`}
                  <br />
                </>
              ) : null
            )}
          </p>
          {product?.product_relateds.map(pr => {
            const extra = getProduct(pr)
            return (
              <Checkbox
                square
                label={
                  isSummary
                    ? `${extra?.product_description[0].name} (+${extra?.price}€)`
                    : `Ajouter l'option ${extra?.product_description[0].name} (+${extra?.price}€)`
                }
                isChecked={
                  extras?.[index] !== undefined &&
                  extras[index].includes(parseInt(pr, 10))
                }
                onChange={() => toggleExtra(index, pr)}
                labelClass="ml-2 mr-0"
                className={`cart-product-item-option d-block ${
                  isSummary &&
                  extras?.[index] !== undefined &&
                  extras[index].includes(parseInt(pr, 10))
                    ? ''
                    : 'disabled'
                }`}
                disabled={isSummary}
              />
            )
          })}
          {!isSummary && (
            <div className="d-none d-md-block float-md-right update-links">
              <Button
                className="pt-0"
                variant="link"
                onClick={() => editProduct(product)}
              >
                Modifier
              </Button>
              <Button className="pt-0" variant="link" onClick={handleDelete}>
                Supprimer
              </Button>
            </div>
          )}
        </Col>
        {!isSummary && (
          <Col xs="12" className="d-block d-md-none ">
            <div className="float-right update-links">
              <Button
                className="pt-0"
                variant="link"
                onClick={() => editProduct(product)}
              >
                Modifier
              </Button>
              <Button className="pt-0" variant="link" onClick={handleDelete}>
                Supprimer
              </Button>
            </div>
          </Col>
        )}
      </Row>
    </>
  )
}

CartProductItem.propTypes = {
  index: propTypes.number.isRequired,
  product: propTypes.objectOf(propTypes.any).isRequired,
  editProduct: propTypes.func,
  isSummary: propTypes.bool,
  cartProduct: propTypes.objectOf(propTypes.any).isRequired,
  selectedProduct: propTypes.objectOf(propTypes.any).isRequired,
  getStudio: propTypes.func.isRequired,
  getProduct: propTypes.func.isRequired,
  extras: propTypes.objectOf(propTypes.any).isRequired,
  toggleExtra: propTypes.func.isRequired,
  handleDelete: propTypes.func.isRequired,
}
CartProductItem.defaultProps = { isSummary: false, editProduct: null }

export default CartProductItem
