import React from 'react'
import Steps, { Step } from 'rc-steps'
import upperFirst from 'lodash/upperFirst'
import propTypes from 'prop-types'
import { STEPS_LABEL } from '../../../constants/steps'

import 'rc-steps/assets/index.css'
import './index.scss'

const ProductSteps = ({ step, setStep, STEPS, isDisabled, isB2C }) => {
  // check if step is fullfilled before change
  const goStep = stepToGo => {
    if (stepToGo > step && isDisabled()) {
      return
    }
    if ((isB2C && !isDisabled) || !isB2C) setStep(stepToGo)
  }

  const renderSteps = () => {
    const steps = []
    Object.entries(STEPS).forEach(([key, value], index) => {
      steps.push(
        <Step
          title={`${index + 1}. ${upperFirst(STEPS_LABEL[key].toLowerCase())}`}
          className="step"
          onClick={() => goStep(value)}
        />
      )
    })
    return steps
  }

  return (
    <Steps progressDot current={step} className="steps">
      {renderSteps()}
    </Steps>
  )
}
ProductSteps.propTypes = {
  step: propTypes.number.isRequired,
  STEPS: propTypes.objectOf(propTypes.number).isRequired,
  setStep: propTypes.func.isRequired,
  isDisabled: propTypes.func.isRequired,
  isB2C: propTypes.bool,
}
ProductSteps.defaultProps = { isB2C: false }
export default ProductSteps
