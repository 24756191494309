import React from 'react'
import propTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import { useMediaQuery } from 'react-responsive'
import ModalCard from '../../app-components/modal-card'
import Checkbox from '../../app-components/checkbox'

const ExpressShippingModal = ({
  extraProduct,
  handleToggle,
  show,
  setShow,
  isChecked,
}) => {
  const isMobile = useMediaQuery({ query: '(max-device-width: 767px)' })
  return (
    <ModalCard
      className="express-shipping-modal"
      title={
        <>
          <p className="pt-4 mb-0 text-center">
            Nous vous livrons sous <strong>24h après sélection,</strong> dans
            votre espace personnel.
          </p>
          <table>
            <tr>
              <td />
              <td />
            </tr>
            <tr>
              <td className="pt-4 px-3">
                <Checkbox
                  square
                  onChange={() => handleToggle(extraProduct.id)}
                  label="Livraison Express"
                  className="d-flex"
                  isChecked={isChecked}
                />
              </td>
              <td className="text-right px-3">
                <span className="font-weight-bold">
                  +{extraProduct?.price}€
                </span>
              </td>
            </tr>
          </table>
        </>
      }
      show={show}
      onHide={() => setShow(false)}
      heading="Votre photo en urgence"
    >
      <Button
        onClick={() => setShow(false)}
        variant={`${isChecked ? 'secondary' : 'outline-primary'}`}
      >
        {isChecked
          ? 'Valider'
          : `Continuer ${isMobile ? 'sans options' : 'en livraison standard'}`}
      </Button>
    </ModalCard>
  )
}

ExpressShippingModal.propTypes = {
  extraProduct: propTypes.objectOf(propTypes.any).isRequired,
  handleToggle: propTypes.func.isRequired,
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired,
  isChecked: propTypes.bool.isRequired,
}

export default ExpressShippingModal
