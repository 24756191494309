import React from 'react'
import propTypes from 'prop-types'
import { Button, ButtonToolbar, Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { navigate } from '@reach/router'
import CartProductItem from '../cart/product-item'
import Icon from '../../app-components/icon'

import {
  getCartProducts,
  getExtras,
  getProductById,
  getProductType,
  getSelectedProduct,
} from '../../../store/offer/selectors'
import { getStudioById } from '../../../store/studios/selectors'
import { doGetProductsByCategory } from '../../../store/offer/actions'

import './styles.scss'

const SummaryStep = ({
  step,
  setStep,
  selectedProduct,
  getStudio,
  user,
  getProduct,
  getType,
  extras,
  handleSubmit,
  cartProducts,
}) => {
  return (
    <Row>
      <Col className="checkout-summary left-content">
        <div className="cart-product-list border-bottom">
          {cartProducts.map((product, index) => (
            <CartProductItem
              index={index}
              cartProduct={product}
              product={getProduct(product.product_id)}
              editProduct={() =>
                navigate('/reservation', {
                  state: { editProduct: product.product_id },
                })
              }
              selectedProduct={selectedProduct}
              getStudio={getStudio}
              getProduct={getProduct}
              getType={getType}
              extras={extras}
              isSummary
            />
          ))}
        </div>
        <Col>
          <Row>
            <Col md={12} className="d-none d-md-block px-md-0 py-md-3">
              <Button
                onClick={() => setStep(step - 1)}
                variant="flat"
                className="float-right pr-md-0"
              >
                <Icon name="icon-edit-pen" />
              </Button>
            </Col>
            <Col xs={12} md={6} className="px-md-0 mt-3 mt-md-0">
              <Button
                onClick={() => setStep(step - 1)}
                variant="flat"
                className="d-block d-md-none float-right pr-md-0"
              >
                <Icon name="icon-edit-pen" />
              </Button>
              <p>
                <strong>
                  {user.lastname} {user.firstname}
                </strong>
                <br />
                {user.tel || selectedProduct.tel}
                <br />
                {user.email}
              </p>
            </Col>
            <Col xs={12} md={6} className="summary-right-column">
              <p>
                <strong>Adresse de facturation</strong> <br />
                {user.address} <br />
                {user.zipcode}, {user.city}
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="px-md-0">
              <ButtonToolbar className="form-buttons flex-column flex-md-row justify-content-between mt-4">
                <Button
                  variant="outline-primary"
                  size="lg"
                  onClick={() => setStep(step - 1)}
                  className="mt-2 mt-md-0 order-2 order-md-1"
                >
                  Retour
                </Button>
                <Button
                  onClick={handleSubmit}
                  size="lg"
                  className="order-1 order-md-2"
                >
                  Procéder au paiement
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Col>
      </Col>
    </Row>
  )
}

SummaryStep.propTypes = {
  step: propTypes.number.isRequired,
  setStep: propTypes.func.isRequired,
  user: propTypes.objectOf(propTypes.any).isRequired,
  selectedProduct: propTypes.objectOf(propTypes.any).isRequired,
  getStudio: propTypes.func.isRequired,
  getProduct: propTypes.func.isRequired,
  getType: propTypes.func.isRequired,
  extras: propTypes.objectOf(propTypes.any).isRequired,
  handleSubmit: propTypes.func.isRequired,
  cartProducts: propTypes.objectOf(propTypes.any).isRequired,
}

const mapStateToProps = state => ({
  selectedProduct: getSelectedProduct(state),
  getStudio: id => getStudioById(state, id),
  getProduct: id => getProductById(state, id),
  getType: id => getProductType(state, id),
  extras: getExtras(state),
  cartProducts: getCartProducts(state),
})
const mapDispatchToProps = dispatch => ({
  getProductsByCategory: category =>
    dispatch(doGetProductsByCategory(category)),
})
export default connect(mapStateToProps, mapDispatchToProps)(SummaryStep)
