import { Accordion, Button, Col, Row } from 'react-bootstrap'
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import Icon from '../../app-components/icon'
import {
  getCart,
  getCartProducts,
  getCartTotalPrice,
  getExtras,
  getProductById,
  getProductType,
  getPromoCode,
} from '../../../store/offer/selectors'
import { doRemoveCoupon, doUpdateExtras } from '../../../store/offer/actions'
import { CART_STEPS } from '../../../constants/steps'

import './styles.scss'

const PriceTotal = ({
  cart,
  handleSubmit,
  handleFormPaymentSubmit,
  step,
  extras,
  toggleExtras,
  getProduct,
  getTotal,
  promoCode,
  cartProducts,
  removeCoupon,
}) => {
  const [showTotal, setShowTotal] = useState(false)

  const ctaText = () => {
    switch (step) {
      case CART_STEPS.PAYMENT:
        return 'Confirmer le paiement'
      default:
        return 'Commander'
    }
  }

  return (
    <div className="checkout-cart right-content">
      <Row className="pt-md-4">
        <Col className="pt-md-3 pb-md-4">
          <h3 className="cart-title">TOTAL COMMANDE</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Accordion
            activeKey={showTotal ? 'tarif' : ''}
            className={`accordion-section ${showTotal ? '' : 'border-bottom'}`}
          >
            <Accordion.Toggle
              as={Row}
              eventKey="tarif"
              onClick={() => setShowTotal(!showTotal)}
            >
              <Col className="d-flex align-items-center">
                <strong>Sous total</strong>{' '}
                <Icon
                  name="icon-arrow-down"
                  iconClass={`icon-arrow-down-fat ml-3 ${
                    !showTotal ? '' : 'up'
                  }`}
                />
              </Col>
              <Col className="text-right">
                <strong>{cart?.totals?.[0]?.text}</strong>
              </Col>
            </Accordion.Toggle>
            <Accordion.Collapse className="total-submenu" eventKey="tarif">
              <table>
                <tbody>
                  {cartProducts.map((product, index) => (
                    <>
                      <tr>
                        <td>{product.name}</td>
                        <td className="text-right">{product.price}</td>
                      </tr>
                      {extras?.[index]?.map(extra => (
                        <tr>
                          <td>
                            {getProduct(extra)?.product_description?.[0]?.name}
                          </td>
                          <td className="text-right">
                            {getProduct(extra)?.price} €{' '}
                            <Button
                              variant="link"
                              onClick={() =>
                                toggleExtras(index, getProduct(extra)?.id)
                              }
                              className="extra-delete"
                            >
                              <Icon name="icon-delete" />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </>
                  ))}
                </tbody>
              </table>
            </Accordion.Collapse>
          </Accordion>
          <table>
            <tbody>
              {cart?.coupon !== '' && (
                <tr className="cart-total-tax">
                  <td>
                    <strong>Code promo</strong>
                  </td>
                  <td className="text-right">
                    {promoCode?.value}
                    {'€ '}
                    <Button
                      variant="link"
                      onClick={removeCoupon}
                      className="extra-delete"
                    >
                      <Icon name="icon-delete" />
                    </Button>
                  </td>
                </tr>
              )}
              <tr className="cart-total">
                <td>
                  <strong>TOTAL</strong>
                </td>
                <td className="text-right">
                  <strong className="total-price">{getTotal}€</strong>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="cart-total-button-wrapper pb-4">
                  <Button block onClick={ctaText().toUpperCase() === "COMMANDER" ? handleSubmit :  handleFormPaymentSubmit}>
                    {ctaText()}
                  </Button>
                </td>
              </tr>
              <tr className="cart-total-icons-wrapper">
                <td className="pr-0">
                  <strong>Paiement sécurisé</strong>
                </td>
                <td className="payment-icons">
                  <Icon name="icon-ic-secure" iconClass="mx-1" />
                  <Icon name="icon-picto-cb-visa" paths={6} iconClass="mx-1" />
                  <Icon
                    name="icon-picto-cb-mastercard"
                    paths={6}
                    iconClass="mx-1"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  )
}
PriceTotal.propTypes = {
  handleSubmit: propTypes.func,
  handleFormPaymentSubmit: propTypes.func,
  step: propTypes.number.isRequired,
  cart: propTypes.objectOf(propTypes.any).isRequired,
  cartProducts: propTypes.arrayOf(propTypes.object).isRequired,
  extras: propTypes.objectOf(propTypes.array).isRequired,
  toggleExtras: propTypes.func.isRequired,
  getProduct: propTypes.func.isRequired,
  getTotal: propTypes.func.isRequired,
  promoCode: propTypes.func.isRequired,
  removeCoupon: propTypes.func.isRequired,
}
PriceTotal.defaultProps = {
  handleSubmit: null,
  handleFormPaymentSubmit: null,
}

const mapStateToProps = state => ({
  cart: getCart(state),
  cartProducts: getCartProducts(state),
  getProduct: id => getProductById(state, id),
  getType: id => getProductType(state, id),
  extras: getExtras(state),
  getTotal: getCartTotalPrice(state),
  promoCode: getPromoCode(state),
})
const mapDispatchToProps = dispatch => ({
  toggleExtras: (productId, extraId) =>
    dispatch(doUpdateExtras(productId, extraId)),
  removeCoupon: () => dispatch(doRemoveCoupon()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PriceTotal)
