import React from 'react'
import { ButtonToolbar, Col, Row } from 'react-bootstrap'
import propTypes from 'prop-types'
import moment from 'moment'
import Button from '../../app-components/button'

const ConfirmationStep = ({ selectedProduct, getStudio, orderId }) => {
  const studio = getStudio(selectedProduct?.studio)
  const date = moment(selectedProduct?.date)

  return (
    <Col className="checkout-confirm left-content">
      <Row>
        <Col>
          <p>
            Nous vous confirmons votre réservation pour{' '}
            <b>
              le {date.format('D/MM/YYYY')} à {date.format('HH:mm')} au{' '}
              {studio.address} {studio?.postalcode}, {studio?.city}.
            </b>
            <br />
            <br />
            Vous recevrez par mail la confirmation de votre{' '}
            <b>commande N°{orderId}.</b>
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonToolbar className="form-buttons justify-content-between mt-4">
            <Button
              className="order-1 order-md-0 mb-3 mb-md-0"
              type="outline-primary"
              size="lg"
              to="/"
              name="Revenir à l'accueil"
              replace
            />
            <Button
              className="order-0 order-md-1"
              size="lg"
              type="secondary"
              to="/compte/projets"
              name="Accéder à mon compte"
              replace
            />
          </ButtonToolbar>
        </Col>
      </Row>
    </Col>
  )
}
ConfirmationStep.propTypes = {
  selectedProduct: propTypes.objectOf(propTypes.any).isRequired,
  getStudio: propTypes.func.isRequired,
  orderId: propTypes.number.isRequired,
}
export default ConfirmationStep
