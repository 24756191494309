import React from 'react'
import propTypes from 'prop-types'
import './styles.scss'

const Checkbox = ({
  id,
  className,
  isChecked,
  onChange,
  square,
  onClick,
  label,
  labelClass,
  disabled,
}) => (
  <div className={className}>
    <input
      id={id}
      checked={isChecked}
      onChange={onChange}
      onClick={onClick}
      type="checkbox"
      className={square ? 'square' : 'rounded-checkbox'}
      disabled={disabled}
    />
    <label htmlFor={id} className={`${labelClass || 'mx-2'}`}>
      {label}
    </label>
  </div>
)
Checkbox.defaultProps = {
  className: '',
  square: true,
  onClick: null,
  onChange: null,
  label: '',
  labelClass: '',
  disabled: false,
}
Checkbox.propTypes = {
  id: propTypes.string.isRequired,
  className: propTypes.string,
  isChecked: propTypes.bool.isRequired,
  onChange: propTypes.func,
  onClick: propTypes.func,
  square: propTypes.bool,
  label: propTypes.string,
  labelClass: propTypes.string,
  disabled: propTypes.bool,
}
export default Checkbox
