import React from 'react'
import { Button, Container } from 'react-bootstrap'
import propTypes from 'prop-types'
import './styles.scss'

const FixedBottomButton = ({ text, handleClick, containerClass, variant }) => (
  <Container className={`${containerClass} fixed-bottom-button p-0 `} fluid>
    <Button
      variant={variant}
      size="lg"
      className="p-4"
      onClick={handleClick}
      block
    >
      {text}
    </Button>
  </Container>
)
FixedBottomButton.propTypes = {
  text: propTypes.string.isRequired,
  handleClick: propTypes.func.isRequired,
  containerClass: propTypes.string,
  variant: propTypes.string,
}
FixedBottomButton.defaultProps = { containerClass: '', variant: 'secondary' }

export default FixedBottomButton
