import React, { useEffect } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useMediaQuery } from 'react-responsive/src'
import Footer from '../../components/Footer'
import Header from '../../components/Header'

import './styles.scss'
import { getUser } from '../../store/authentication/selectors'
import { doLogout } from '../../store/authentication/actions'
import LoadingIndicator from '../../components/app-components/loading-indicator'
import { doGetStudioList } from '../../store/studios/actions'

const CheckoutLayout = ({
  children,
  user,
  logout,
  rightColumn,
  bottomButton,
  isLoading,
  fetchStudios,
}) => {
  const isLargeScreen = useMediaQuery({ query: '(min-device-width: 1200px)' })
  useEffect(() => {
    async function init() {
      await fetchStudios()
    }
    init()
  }, [])
  return (
    <div className="checkout-layout">
      <Helmet>
        <link
          rel="stylesheet"
          href="https://api.systempay.fr/static/js/krypton-client/V4.0/ext/material-reset.css"
        />
        <link
          rel="stylesheet"
          href="https://api.systempay.fr/static/js/krypton-client/V4.0/ext/material.css"
        />
        <script src="https://api.systempay.fr/static/js/krypton-client/V4.0/ext/material.js" />
      </Helmet>
      <Header user={user} logout={logout} isCheckout />
      <Container className="cart-page">
        <Row>
          <Col xs={12} md={8} className="left-column py-md-4 mb-4">
            {children}
          </Col>
          <Col xs={12} md={4} className="right-column bg-grey pl-md-5 pt-4">
            {rightColumn()}
          </Col>
        </Row>
        {isLoading && <LoadingIndicator />}
      </Container>
      {!isLargeScreen && bottomButton()}
      <Footer />
    </div>
  )
}
CheckoutLayout.propTypes = {
  children: propTypes.node,
  user: propTypes.objectOf(propTypes.any),
  logout: propTypes.func.isRequired,
  rightColumn: propTypes.func,
  bottomButton: propTypes.func,
  isLoading: propTypes.bool.isRequired,
  fetchStudios: propTypes.func.isRequired,
}
CheckoutLayout.defaultProps = {
  children: null,
  rightColumn: null,
  bottomButton: null,
  user: null,
}

const mapStateToProps = state => ({
  user: getUser(state),
  isLoading: state.offer.isLoading,
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(doLogout()),
  fetchStudios: () => dispatch(doGetStudioList()),
})
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutLayout)
