import React, { useState } from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import propTypes from 'prop-types'
import upperFirst from 'lodash/upperFirst'
import he from 'he'

import ModalCard from '../../app-components/modal-card'
import CustomSelect from '../../app-components/custom-select'
import { STEPS_LABEL } from '../../../constants/steps'

const ProductEditModal = ({
  product,
  selectedProduct,
  handleEdit,
  show,
  setShow,
}) => {
  const [newProduct, setNewProduct] = useState(selectedProduct)

  const makeOptions = options => {
    const optionList = []
    options.forEach(ov => {
      optionList.push({
        value: ov.product_option_value_id,
        label: he.decode(ov.name),
      })
    })
    return optionList
  }

  return (
    <ModalCard
      className="cart-modal"
      show={show}
      onHide={() => setShow(false)}
      heading="Modifier mon produit"
    >
      {product?.options?.map(
        option =>
          Object.keys(STEPS_LABEL).includes(option?.name) && (
            <Row key={option?.name}>
              <Col>
                <strong>{upperFirst(STEPS_LABEL[option?.name])}</strong>
              </Col>
              <Col>
                <CustomSelect
                  name={option?.name}
                  options={makeOptions(option?.option_value)}
                  defaultValue={
                    selectedProduct?.selectedOptions?.[
                      option?.product_option_id
                    ]
                  }
                  selected={val => {
                    setNewProduct({
                      ...newProduct,
                      selectedOptions: {
                        ...newProduct.selectedOptions,
                        [option?.product_option_id]: val,
                      },
                    })
                  }}
                  placeholder=""
                />
              </Col>
            </Row>
          )
      )}
      <Row>
        <Col className="text-center">
          <Button
            onClick={() => handleEdit(newProduct)}
            size="lg"
            className="mt-2 mx-2"
            variant="secondary"
          >
            MODIFIER
          </Button>
        </Col>
      </Row>
    </ModalCard>
  )
}
ProductEditModal.propTypes = {
  product: propTypes.objectOf(propTypes.any).isRequired,
  selectedProduct: propTypes.objectOf(propTypes.any).isRequired,
  handleEdit: propTypes.func.isRequired,
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired,
}
export default ProductEditModal
