import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import DefaultLayout from '../../../layouts/default'
import Banner from '../../sections/_banner/default'
import BannerYellowRoundImg from '../../../assets/images/booke-yellow-vertical-lines.png'
import Heading from '../../app-components/dashed-heading'
import Icon from '../../app-components/icon'
import LoginForm from '../../app-components/login-form'

import './styles.scss'
import { getCartProduct } from '../../../store/offer/selectors'

const LoginOrSignup = ({ handleLogin, handleSignup, cartUser }) => {
  return (
    <DefaultLayout>
      <Banner
        title="Authentification"
        img={BannerYellowRoundImg}
        bannerClass="login-banner"
      />
      <Container className="checkout-auth">
        <Row className="my-5">
          <Col xs={12} lg={6} className="border-right">
            <Row>
              <Col>
                <Heading
                  className="connexion-page-title"
                  title="J'ai déjà un compte"
                />
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <Icon name="icon-login" iconClass="login-wrapper" paths={9} />
                <p className="pt-3 text-primary">
                  <strong>Connectez</strong> vous ici !
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <LoginForm
                  handleSubmit={handleLogin}
                  formCols={{ xs: 12 }}
                  buttonText="ME CONNECTER"
                  formData={{ email: cartUser.email }}
                  isCheckout
                />
              </Col>
            </Row>
            <Row className="d-block d-md-none px-4 mb-3">
              <Col className="border-bottom" />
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <Row className="text-center">
              <Col>
                <Heading
                  className="connexion-page-title"
                  title="Je crée un compte"
                />
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <Icon
                  name="icon-photoshoot"
                  iconClass="login-wrapper"
                  paths={5}
                />
                <p className="pt-3 text-primary">
                  Pas encore de compte ? <strong>Créez-en un ici</strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <LoginForm
                  handleSubmit={handleSignup}
                  formCols={{ xs: 12 }}
                  buttonText="CRÉER UN COMPTE"
                  formData={{ email: cartUser.email }}
                  isSignup
                  isCheckout
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </DefaultLayout>
  )
}

LoginOrSignup.propTypes = {
  handleLogin: propTypes.func.isRequired,
  handleSignup: propTypes.func.isRequired,
  cartUser: propTypes.objectOf(propTypes.any).isRequired,
}
const mapStateToProps = state => ({
  cartUser: getCartProduct(state),
})
export default connect(mapStateToProps)(LoginOrSignup)
