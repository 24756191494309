import React, { useEffect, useRef, useState } from 'react'
import { Router } from '@reach/router'
import propTypes from 'prop-types'

import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import _startCase from 'lodash/startCase'
import _camelCase from 'lodash/camelCase'
import { Col, Row } from 'react-bootstrap'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import LoginOrSignup from '../../components/checkout/login-signup'
import CartStep from '../../components/checkout/cart'
import BillingStep from '../../components/checkout/billing'
import SummaryStep from '../../components/checkout/summary'
import PaymentStep from '../../components/checkout/payment'
import ConfirmationStep from '../../components/checkout/confirmation'
import { doLogin, doUpdateAddress } from '../../store/authentication/actions'
import { getUser } from '../../store/authentication/selectors'
import {
  getCart,
  getCartProduct,
  getOrderId,
} from '../../store/offer/selectors'
import {
  doGetCart,
  doUpdatePayment,
  doGetTokenRc,
} from '../../store/offer/actions'
import { getStudioById } from '../../store/studios/selectors'
import CheckoutLayout from '../../layouts/checkout'
import PriceTotal from '../../components/checkout/price-total'
import FixedBottomButton from '../../components/app-components/fixed-bottom-button'
import { CART_STEPS } from '../../constants/steps'
import PortfolioSlider from '../../components/sections/portfolio/slider'
import Breadcrumbs from '../../components/app-components/breadcrumbs'
import ProductSteps from '../../components/app-components/product-steps'

import './styles.scss'
import { doConfirmB2CStudioSession } from '../../store/studios/actions'

const title = 'portrait'
const ReservationTemplate = ({
  navigate,
  location,
  user,
  login,
  data,
  fetchCart,
  cart,
  getStudio,
  cartProduct,
  updateUser,
  updatePayment,
  confirmStudioSession,
  orderId,
  getTokenRc,
}) => {
  // change state onload for correct url management
  const getStep = () => {
    switch (location.pathname) {
      case '/reservation/facturation':
      case '/reservation/connexion-creation':
        return CART_STEPS.BILLING
      case '/reservation/recapitulatif':
        return CART_STEPS.SUMMARY
      case '/reservation/paiement':
        return CART_STEPS.PAYMENT
      case '/reservation/confirmation':
        return CART_STEPS.CONFIRM
      case '/reservation':
      default:
        return CART_STEPS.CART
    }
  }

  const [step, setStep] = useState(getStep())

  const [acceptTermsAndConditions, toggleTermsAndConditions] = useState(false)
  const [submitted, hadSubmit] = useState(false)

  const [paymentForm, setPaymentForm] = useState(null)
  const [orderPublicId, setOrderPublicId] = useState("")
  const [rcOrderId, setRcOrderId] = useState("")
  const rcRef = useRef(null)

  // redirect on step change
  useEffect(() => {
    switch (step) {
      case CART_STEPS.CART:
        navigate('/reservation')
        break
      case CART_STEPS.BILLING: {
        if (user !== null) {
          navigate('/reservation/facturation')
          break
        }
        navigate('/reservation/connexion-creation')
        break
      }
      case CART_STEPS.SUMMARY:
        navigate('/reservation/recapitulatif')
        break
      case CART_STEPS.PAYMENT:
        navigate('/reservation/paiement')
        break
      case CART_STEPS.CONFIRM:
        navigate('/reservation/confirmation')
        break
      default:
        break
    }
  }, [step, user])
  // change step on location update
  useEffect(() => {
    setStep(getStep())
  }, [location.pathname])

  // fetch the cart content
  useEffect(() => {
    fetchCart()
  }, [])

  // redirect to SignupPage w/ form data
  const handleSignup = formData =>
    navigate('/inscription', {
      state: {
        signupData: {
          ...formData,
          'signup-lastname': cartProduct.lastName,
          'signup-firstname': cartProduct.firstName,
        },
      },
    })

  // Billing Form
  const billingFormSchema = yup.object().shape({
    address: yup.string().required(),
    zipcode: yup
      .number()
      .integer()
      .positive()
      .max(99999),
    city: yup.string().required(),
  })
  const { register, errors, handleSubmit, formState, setValue } = useForm({
    validationSchema: billingFormSchema,
    mode: 'onChange',
  })

  const handleChange = ({ target: { value, name } }) => {
    setValue(name, value, true)
  }

  const checkStep = () => {
    if (step === CART_STEPS.CONFIRM) return true
    return false
  }

  const submit = async event => {
    switch (step) {
      case CART_STEPS.CART: {
        hadSubmit(true)
        if (acceptTermsAndConditions) setStep(step + 1)
        break
      }
      case CART_STEPS.BILLING: {
        handleSubmit(formData => {
          if (formState.isValid) {
            if (user) updateUser(formData)
            setStep(step + 1)
          }
        })(event)
        break
      }
      case CART_STEPS.SUMMARY:{
        const createRevolutOrder = await getTokenRc(user)
        if(createRevolutOrder && createRevolutOrder?.public_id){
          setOrderPublicId(createRevolutOrder?.public_id)
          setRcOrderId(createRevolutOrder?.id)
          setStep(step + 1)
        }
        break
      }
      case CART_STEPS.PAYMENT: {
        try{
          const order = await updatePayment(rcOrderId, null, 1)
          if (order) {
            // confirm studio session
            await confirmStudioSession(cartProduct, user, order)
            setStep(step + 1)
          }
          
        } catch (e) {
          console.error(e)
        }
        break
      }
      default:
        setStep(step + 1)
        break
    }
  }

  const handleFormPaymentSubmit = async () => {
    rcRef.current.submit({
      name: user.firstname + ' ' + user.lastname,
      email: user.email,
      billingAddress: {
        countryCode: 'FR',
        city: user.city,
        streetLine1: user.address,
        postcode: user.zipcode,
      },
    })
  }

  const renderRightColumn = () => {
    if (step === CART_STEPS.CONFIRM) {
      return (
        <Row>
          <Col className="checkout-confirm right-content confirm">
            <h3 className="mt-4">À découvrir aussi…</h3>
            <PortfolioSlider variant="checkout" />
          </Col>
        </Row>
      )
    }

    return <PriceTotal cart={cart} step={step} handleSubmit={submit} handleFormPaymentSubmit={handleFormPaymentSubmit}/>
  }

  const renderBottomButton = () => {
    const text = () => {
      switch (step) {
        case CART_STEPS.BILLING:
          return 'Enregistrer et continuer'
        case CART_STEPS.SUMMARY:
          return 'Procéder au paiement'
        case CART_STEPS.PAYMENT:
          return 'Confirmer le paiement'
        default:
          return 'COMMANDER'
      }
    }
    return (
      step <= CART_STEPS.PAYMENT && (
        <FixedBottomButton
          text={text()}
          handleClick={submit}
          containerClass="m-0"
          variant="secondary"
        />
      )
    )
  }

  const defaultProps = {
    title,
    step,
    setStep,
    user,
    data,
    cart,
    handleSubmit: submit,
  }

  return step !== CART_STEPS.BILLING || user !== null ? (
    <CheckoutLayout
      step={step}
      setStep={setStep}
      rightColumn={renderRightColumn}
      bottomButton={renderBottomButton}
    >
      <Breadcrumbs
        route={[
          { title: 'Accueil', path: '/' },
          { title: 'Nos offres', path: '/particuliers/' },
          {
            title: _startCase(_camelCase(title)),
            path: `/particuliers/${title.toLowerCase()}/`,
          },
          { title: 'Facturation' },
        ]}
      />
      <Row>
        <Col xs={12} className="my-md-2">
          <ProductSteps
            step={step}
            setStep={setStep}
            STEPS={CART_STEPS}
            isDisabled={checkStep}
            isB2C
          />
        </Col>
      </Row>
      <Router basepath="/reservation">
        <CartStep
          path="/"
          acceptTermsAndConditions={acceptTermsAndConditions}
          toggleTermsAndConditions={toggleTermsAndConditions}
          submitted={submitted}
          hadSubmit={hadSubmit}
          {...defaultProps}
        />
        <BillingStep
          path="facturation"
          register={register}
          errors={errors}
          handleChange={handleChange}
          {...defaultProps}
        />
        <SummaryStep path="recapitulatif" {...defaultProps} />
        <PaymentStep
          path="paiement"
          form={paymentForm}
          setPaymentForm={setPaymentForm}
          orderPublicId={orderPublicId}
          rcOrderId={rcOrderId}
          rcRef={rcRef}
          handleFormPaymentSubmit={handleFormPaymentSubmit}
          {...defaultProps}
        />
        <ConfirmationStep
          path="confirmation"
          selectedProduct={cartProduct}
          getStudio={getStudio}
          orderId={orderId}
          {...defaultProps}
        />
      </Router>
    </CheckoutLayout>
  ) : (
    /* other layout on LoginSignup */
    <Router basepath="/reservation">
      <LoginOrSignup
        path="connexion-creation"
        handleLogin={formData => login(formData, '/reservation/facturation')}
        handleSignup={handleSignup}
      />
    </Router>
  )
}

export const query = graphql`
  query ReservationPage($id: String!) {
    wpPage(id: { eq: $id }) {
      slug
      title
      id
      ...yoastMeta
    }
  }
`
ReservationTemplate.propTypes = {
  navigate: propTypes.func.isRequired,
  location: propTypes.objectOf(propTypes.any).isRequired,
  data: propTypes.objectOf(propTypes.any).isRequired,
  user: propTypes.objectOf(propTypes.any),
  login: propTypes.func.isRequired,
  fetchCart: propTypes.func.isRequired,
  getStudio: propTypes.func.isRequired,
  cart: propTypes.objectOf(propTypes.any),
  cartProduct: propTypes.objectOf(propTypes.any).isRequired,
  updatePayment: propTypes.func.isRequired,
  updateUser: propTypes.func.isRequired,
  confirmStudioSession: propTypes.func.isRequired,
  orderId: propTypes.number,
}

ReservationTemplate.defaultProps = { user: null, cart: null, orderId: null }

const mapStateToProps = state => ({
  user: getUser(state),
  cart: getCart(state),
  cartProduct: getCartProduct(state),
  getStudio: id => getStudioById(state, id),
  orderId: getOrderId(state),
})

const mapDispatchToProps = dispatch => ({
  login: (data, path) => dispatch(doLogin(data, path, true)),
  fetchCart: () => dispatch(doGetCart()),
  updateUser: value => dispatch(doUpdateAddress(value)),
  updatePayment: (transaction, msg, success) =>
    dispatch(doUpdatePayment(transaction, msg, success)),
  confirmStudioSession: (product, user, orderId) =>
    dispatch(doConfirmB2CStudioSession(product, user, orderId)),
  getTokenRc: (user) => dispatch(doGetTokenRc(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReservationTemplate)
