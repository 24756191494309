import React from 'react'

import { Button, ButtonToolbar, Col, Form, Row } from 'react-bootstrap'
import propTypes from 'prop-types'
import ErrorMessage from '../../app-components/form-error-message'

import './styles.scss'

const BillingStep = ({
  step,
  setStep,
  user,
  register,
  errors,
  handleChange,
  handleSubmit,
}) => {
  return (
    <Row className="billing-step">
      <Col>
        <h3 className="billing-step-title">
          Adresse de facturation
          <span className="mandatory-label text-muted float-right">
            Champs obligatoires*
          </span>
        </h3>
        <Form onSubmit={handleSubmit}>
          <Form.Row className="mb-lg-3">
            <Form.Group
              as={Col}
              xs={12}
              className="form-label-group"
              controlId="address"
            >
              <Form.Control
                type="text"
                placeholder="Adresse (rue, lieu dit, étage, etc.)*"
                name="address"
                defaultValue={user.address}
                ref={register}
                onChange={handleChange}
                isInvalid={errors.address !== undefined}
              />
              <Form.Label>Adresse (rue, lieu dit, étage, etc.)*</Form.Label>
              <ErrorMessage name="address" errors={errors} />
            </Form.Group>
            <Form.Group
              as={Col}
              xs={12}
              lg={6}
              className="form-label-group"
              controlId="zipcode"
            >
              <Form.Control
                placeholder="Code postal"
                type="text"
                name="zipcode"
                defaultValue={user.zip || user.zipcode}
                ref={register}
                onChange={handleChange}
                isInvalid={errors.zipcode !== undefined}
              />
              <Form.Label>Code postal</Form.Label>
              <ErrorMessage name="zipcode" errors={errors} />
            </Form.Group>
            <Form.Group
              as={Col}
              xs={12}
              lg={6}
              className="form-label-group"
              controlId="city"
            >
              <Form.Control
                placeholder="Ville*"
                name="city"
                type="text"
                ref={register}
                defaultValue={user.city}
                isInvalid={errors.city !== undefined}
                onChange={handleChange}
              />
              <Form.Label>Ville*</Form.Label>
              <ErrorMessage name="city" errors={errors} />
            </Form.Group>
          </Form.Row>
          <ButtonToolbar className="form-buttons justify-content-between mt-4">
            <Button
              variant="outline-primary"
              onClick={() => setStep(step - 1)}
              size="lg"
              className="button-prev"
            >
              Retour
            </Button>
            <Button
              variant="primary"
              size="lg"
              type="submit"
              className="d-none d-md-block"
            >
              Enregistrer et continuer
            </Button>
          </ButtonToolbar>
        </Form>
      </Col>
    </Row>
  )
}

BillingStep.propTypes = {
  step: propTypes.number.isRequired,
  setStep: propTypes.func.isRequired,
  user: propTypes.objectOf(propTypes.any).isRequired,
  register: propTypes.func.isRequired,
  errors: propTypes.objectOf(propTypes.any).isRequired,
  handleChange: propTypes.func.isRequired,
  handleSubmit: propTypes.func.isRequired,
}

export default BillingStep
