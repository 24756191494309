import React, { useState } from 'react'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import propTypes from 'prop-types'
import Link from '../link'
import ErrorMessage from '../form-error-message'
import { trackGTM } from '../../../helpers/index'
import './styles.scss'

const LoginForm = ({
  handleSubmit,
  path,
  isSignup,
  formCols,
  buttonText,
  isCheckout,
  formData = null,
}) => {
  const prefixField = field => (isSignup ? `signup-${field}` : field)
  const [form, setForm] = useState({
    [prefixField('email')]: formData?.email || '',
    [prefixField('password')]: '',
  })

  const [showPassword, setShowPassword] = useState(false)

  const formSchema = yup.object().shape({
    [prefixField('email')]: yup
      .string()
      .required()
      .email()
      .max(100),
    [prefixField('password')]: yup.string().required(),
  })
  const {
    register,
    errors,
    setError,
    formState,
    setValue,
    handleSubmit: wrapSubmit,
  } = useForm({
    validationSchema: formSchema,
    mode: 'onChange',
  })

  const handleChange = ({ target: { value, name } }) => {
    // validate form on input change
    setValue(name, value, true)
    setForm({ ...form, [name]: value })
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const submit = async e => {
    e.preventDefault()
    let res
    if (path === null) res = await handleSubmit(form)
    else res = await handleSubmit(form, path)

    if (res !== true) {
      setError([
        { name: 'login', type: res },
        { name: 'email', type: res },
        { name: 'password' },
      ])
    } else {
      trackGTM({
        event: isSignup ? 'createAccountForm' : 'connectForm',
        tunnel: isCheckout,
      })
    }
  }

  return (
    <Container fluid className="login-form">
      <Row className="justify-content-center">
        <Col {...formCols}>
          <Form onSubmit={e => wrapSubmit(submit(e))}>
            <ErrorMessage name="login" errors={errors} />
            <Form.Group
              className="form-label-group"
              controlId={prefixField('email')}
            >
              <Form.Control
                type="email"
                placeholder="Email"
                name={prefixField('email')}
                ref={register}
                defaultValue={form[prefixField('email')]}
                onChange={handleChange}
                isInvalid={typeof errors.email !== 'undefined'}
              />
              <Form.Label>Email</Form.Label>
              <ErrorMessage name="email" errors={errors} />
            </Form.Group>

            <Form.Group
              className="form-label-group"
              controlId={prefixField('password')}
            >
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder="Mot de passe"
                name={prefixField('password')}
                className={!formState.touched ? 'login-form-confirm' : ''}
                ref={register}
                defaultValue={form[prefixField('password')]}
                onChange={handleChange}
                isInvalid={typeof errors.password !== 'undefined'}
              />
              <Form.Label>Mot de passe</Form.Label>
              <span
                onClick={togglePasswordVisibility}
                className={`password-toggle ${
                  showPassword ? 'icon-booke-hide' : 'icon-booke-show'
                }`}
              />
              <ErrorMessage name="password" errors={errors} />
              {!isSignup ? (
                <Link
                  to="/mot-de-passe-oublie"
                  state={{ email: form[prefixField('email')] }}
                  className="d-block forgot-link text-center pt-2"
                >
                  Mot de passe oublié ?
                </Link>
              ) : (
                <br />
              )}
            </Form.Group>
            <div className="text-center mt-5 mt-lg-3">
              <Button
                size="lg"
                variant={
                  formState.dirty && formState.isValid
                    ? 'secondary'
                    : 'disabled'
                }
                type="submit"
                disabled={!formState.isValid}
              >
                {buttonText}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
LoginForm.propTypes = {
  handleSubmit: propTypes.func.isRequired,
  path: propTypes.string,
  isSignup: propTypes.bool,
  isCheckout: propTypes.bool,
  formCols: propTypes.objectOf(propTypes.any),
  buttonText: propTypes.string,
  formData: propTypes.objectOf(propTypes.string),
}
LoginForm.defaultProps = {
  isSignup: false,
  isCheckout: false,
  formCols: { md: { span: 6 }, sm: { span: 6 } },
  buttonText: 'VALIDER',
  path: null,
  formData: null,
}
export default LoginForm
