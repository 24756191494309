import React, { useEffect, useRef, useState } from 'react'
import propTypes from 'prop-types'
import { Button, ButtonToolbar, Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import RevolutCheckout from '@revolut/checkout'
import LoadingIndicator from '../../app-components/loading-indicator'
import { doGetOrderRc } from '../../../store/offer/actions'

import './styles.scss'
const PaymentStep = ({
  cart,
  step,
  setStep,
  isLoading,
  user,
  orderPublicId,
  rcOrderId,
  getOrderRc,
  handleSubmit,
  rcRef,
  handleFormPaymentSubmit,
}) => {
  const cardElementRef = useRef(null)
  const [cardErrors, setCardErrors] = useState([])
  const [paymentError, setPaymentError] = useState('')

  useEffect(() => {

    
    if (orderPublicId && orderPublicId?.length > 0) {
      RevolutCheckout(orderPublicId, process.env.GATSBY_REVOLUT_CHECKOUT_MODE).then((RC) => {
        rcRef.current = RC.createCardField({
          target: cardElementRef.current,
          hidePostcodeField: true,
          locale: 'fr',

          onValidation(validationCardErrors) {
            // error management for payment information entry
            setCardErrors(validationCardErrors)
            setPaymentError('')
          },
          onSuccess: async () => {
            const createRevolutOrder = await getOrderRc(rcOrderId)
            if (
              createRevolutOrder?.type === 'PAYMENT' && 
              (createRevolutOrder?.state === 'PROCESSING' ||
              createRevolutOrder?.state === 'AUTHORISED' ||
              createRevolutOrder?.state === 'COMPLETED')
            ) {
              handleSubmit()
            }else{
              setPaymentError(
                "Le paiement n'a pas pu être finalisé en raison d'un problème inattendu. Veuillez essayer à nouveau."
              )
            }
          },
          onError(paymentError) {
            setPaymentError(paymentError.message)
          },
          onCancel() {
            console.log('abandon de paiement')
          },
        })
      })
    }
  }, [orderPublicId,user, cart])


  return (
    <Col className="checkout-payment left-content">
      <h4>Vos informations de paiement</h4>
      {isLoading && <LoadingIndicator />}
      <form onSubmit={handleFormPaymentSubmit}>
      <div>
        <label>Carte</label>
        <div id="paymentForm" name="card" ref={cardElementRef} />
      </div>
      <Row>
          <div className="form-field-error-message">
            <ul className={`error-label`}>
              {cardErrors.map((error) => (
                <li>
                  <span className={`error-label`}>{error.message}</span>
                </li>
              ))}
              {paymentError && (
                <li>
                  <span className={`error-label`}>{paymentError}</span>
                </li>
              )}
            </ul>
          </div>
      </Row>
      <ButtonToolbar className="d-none d-md-flex form-buttons justify-content-between mt-4">
        <Button
          variant="outline-primary"
          onClick={() => setStep(step - 1)}
          size="lg"
          className="button-prev"
        >
          Retour
        </Button>
        <Button variant="secondary" size="lg" onClick={handleFormPaymentSubmit}>
          Confirmer le paiement
        </Button>
      </ButtonToolbar>
      </form>
    </Col>
  )
}

PaymentStep.propTypes = {
  step: propTypes.number.isRequired,
  setStep: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
  cart: propTypes.objectOf(propTypes.any).isRequired,
  user: propTypes.objectOf(propTypes.any).isRequired,
  orderPublicId: propTypes.string.isRequired,
  rcOrderId: propTypes.string.isRequired,
  getOrderRc: propTypes.func.isRequired,
  rcRef: propTypes.object.isRequired,
  handleSubmit: propTypes.func.isRequired,
  handleFormPaymentSubmit: propTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  getOrderRc: (rcOrderId) => dispatch(doGetOrderRc(rcOrderId)),
})

export default connect(null, mapDispatchToProps)(PaymentStep)
